// Section animations

.animation-scale {
  position: relative;
  opacity: 0;
  transform: scale(0.6);

  &.animated {
    opacity: 1;
    transform: scale(1);
    animation: animate-in-scale 0.5s ease;
  }
}

@keyframes animate-in-scale {
  from {
    transform: scale(0.6);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animation-up {
  position: relative;
  opacity: 0;
  transform: translateY(50px);

  &.animated {
    opacity: 1;
    transform: none;
    animation: animate-in-up 0.5s ease;
  }
}

@keyframes animate-in-up {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.animation-left {
  position: relative;
  opacity: 0;
  transform: translateX(50px);

  &.animated {
    opacity: 1;
    transform: none;
    animation: animate-in-left 0.5s ease;
  }
}

@keyframes animate-in-left {
  from {
    transform: translateX(50px);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.animation-right {
  position: relative;
  opacity: 0;
  transform: translateX(-50px);

  &.animated {
    opacity: 1;
    transform: none;
    animation: animate-in-right 0.5s ease;
  }
}

@keyframes animate-in-right {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.page {
  --spacing-m-1: 8px;
  --spacing-t-1: 12px;
  --spacing-d-1: 16px;

  --spacing-m-2: 12px;
  --spacing-t-2: 16px;
  --spacing-d-2: 24px;

  --spacing-m-3: 16px;
  --spacing-t-3: 24px;
  --spacing-d-3: 32px;

  --spacing-m-4: 24px;
  --spacing-t-4: 32px;
  --spacing-d-4: 48px;

  --spacing-m-5: 32px;
  --spacing-t-5: 48px;
  --spacing-d-5: 64px;

  --spacing-m-6: 48px;
  --spacing-t-6: 64px;
  --spacing-d-6: 96px;

  --spacing-m-7: 64px;
  --spacing-t-7: 96px;
  --spacing-d-7: 128px;

  &.standard-spacing {
    &:not(.headless) {
      padding-top: var(--spacing-m-7);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-7);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-7);
      }
    }

    &.spacing-top-1 {
      padding-top: var(--spacing-m-1);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-1);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-1);
      }
    }
    &.spacing-top-2 {
      padding-top: var(--spacing-m-2);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-2);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-2);
      }
    }
    &.spacing-top-3 {
      padding-top: var(--spacing-m-3);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-3);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-3);
      }
    }
    &.spacing-top-4 {
      padding-top: var(--spacing-m-4);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-4);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-4);
      }
    }
    &.spacing-top-5 {
      padding-top: var(--spacing-m-5);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-5);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-5);
      }
    }
    &.spacing-top-6 {
      padding-top: var(--spacing-m-6);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-6);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-6);
      }
    }
    &.spacing-top-7 {
      padding-top: var(--spacing-m-7);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-top: var(--spacing-t-7);
      }
      @media (min-width: 960px) {
        padding-top: var(--spacing-d-7);
      }
    }

    &.spacing-top--1,
    &.spacing-top--0-5 {
      padding-top: 0 !important;
    }

    &.mobile-spacing-top-0 {
      @media (max-width: 480px) {
        padding-top: 0 !important;
      }
    }
    &.mobile-spacing-top-1 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-1) !important;
      }
    }
    &.mobile-spacing-top-2 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-2) !important;
      }
    }
    &.mobile-spacing-top-3 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-3) !important;
      }
    }
    &.mobile-spacing-top-4 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-4) !important;
      }
    }
    &.mobile-spacing-top-5 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-5) !important;
      }
    }
    &.mobile-spacing-top-6 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-6) !important;
      }
    }
    &.mobile-spacing-top-7 {
      @media (max-width: 480px) {
        padding-top: var(--spacing-m-7) !important;
      }
    }

    &:not(.bottomless) {
      padding-bottom: var(--spacing-m-7);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-7);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-7);
      }
    }

    &.spacing-bottom-1 {
      padding-bottom: var(--spacing-m-1);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-1);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-1);
      }
    }
    &.spacing-bottom-2 {
      padding-bottom: var(--spacing-m-2);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-2);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-2);
      }
    }
    &.spacing-bottom-3 {
      padding-bottom: var(--spacing-m-3);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-3);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-3);
      }
    }
    &.spacing-bottom-4 {
      padding-bottom: var(--spacing-m-4);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-4);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-4);
      }
    }
    &.spacing-bottom-5 {
      padding-bottom: var(--spacing-m-5);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-5);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-5);
      }
    }
    &.spacing-bottom-6 {
      padding-bottom: var(--spacing-m-6);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-6);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-6);
      }
    }
    &.spacing-bottom-7 {
      padding-bottom: var(--spacing-m-7);
      @media (min-width: 640px) and (max-width: 960px) {
        padding-bottom: var(--spacing-t-7);
      }
      @media (min-width: 960px) {
        padding-bottom: var(--spacing-d-7);
      }
    }

    &.spacing-bottom--1,
    &.spacing-bottom--0-5 {
      padding-bottom: 0 !important;
    }

    &.mobile-spacing-bottom-0 {
      @media (max-width: 480px) {
        padding-bottom: 0 !important;
      }
    }
    &.mobile-spacing-bottom-1 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-1) !important;
      }
    }
    &.mobile-spacing-bottom-2 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-2) !important;
      }
    }
    &.mobile-spacing-bottom-3 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-3) !important;
      }
    }
    &.mobile-spacing-bottom-4 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-4) !important;
      }
    }
    &.mobile-spacing-bottom-5 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-5) !important;
      }
    }
    &.mobile-spacing-bottom-6 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-6) !important;
      }
    }
    &.mobile-spacing-bottom-7 {
      @media (max-width: 480px) {
        padding-bottom: var(--spacing-m-7) !important;
      }
    }
  }

  &.no-spacing {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page {
  &.spacing-bottom--1 {
    > * {
      display: block;
      margin-bottom: -10%;
    }
  }
  &.spacing-top--1 {
    > * {
      display: block;
      margin-top: -10%;
    }
  }
  &.spacing-bottom--0-5 {
    > * {
      display: block;
      margin-bottom: -5%;
    }
  }
  &.spacing-top--0-5 {
    > * {
      display: block;
      margin-top: -5%;
    }
  }
}
