[appbutton] {
  display: none;
}

.custom-button {
  display: flex;
  align-items: center;
  width: fit-content;
  border: none;
  background-color: transparent;
  outline: none;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  transition:
    color 0.2s ease,
    opacity 0.2s ease;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  margin: 0;
  height: 48px;
  position: relative;
  color: var(--color-grey-80);
  z-index: 1;

  img {
    width: 32px;
    height: 32px;
  }

  p {
    display: inline;
    margin: 0 !important;
    font-size: inherit;

    b {
      font-weight: 900;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s ease;
    border-radius: inherit;
  }

  // Spacing
  &.bs--2 {
    padding: 0;
  }
  &.bs--1 {
    padding: 0 0.175em;
  }
  &.bs-1 {
    padding: 0 0.5em;
  }
  &.bs-1-5 {
    padding: 0 0.75em;
  }
  &.bs-2 {
    padding: 0 0.85715em;
  }
  &.bs-2-25 {
    padding: 0 0.9em;
  }
  &.bs-2-5 {
    padding: 0 1.25em;
  }
  &.bs-3 {
    padding: 0 1.5em;
  }
  &.bs-4 {
    padding: 0 2em;
  }
  &.bs-5 {
    padding: 0 3em;
  }
  &.bs-6 {
    padding: 0 3.5em;
  }
  &.bs-8 {
    padding: 0 5em;
  }
  &.bs-full {
    padding: 0;
    width: 100%;
  }
  @media (max-width: 600px) {
    &.bs-mobile-2 {
      padding: 0;
    }
    &.bs-mobile-1 {
      padding: 0 0.5em;
    }
    &.bs-mobile-1-5 {
      padding: 0 0.75em;
    }
    &.bs-mobile-2 {
      padding: 0 0.85715em;
    }
    &.bs-mobile-2-5 {
      padding: 0 1.25em;
    }
    &.bs-mobile-3 {
      padding: 0 1.5em;
    }
    &.bs-mobile-4 {
      padding: 0 2em;
    }
    &.bs-mobile-5 {
      padding: 0 3em;
    }
    &.bs-mobile-6 {
      padding: 0 3.5em;
    }
    &.bs-mobile-8 {
      padding: 0 5em;
    }
    &.bs-mobile-full {
      padding: 0;
      width: 100%;
    }
  }

  // Height
  &.bh--3 {
    height: 14px;
  }
  &.bh--2 {
    height: 20px;
  }
  &.bh--1-5 {
    height: 24px;
  }
  &.bh--1 {
    height: 28px;
  }
  &.bh-0 {
    height: 32px;
  }
  &.bh-0-5 {
    height: 36px;
  }
  &.bh-1 {
    height: 40px;
  }
  &.bh-2 {
    height: 48px;
  }
  &.bh-3 {
    height: 56px;
  }
  &.bh-4 {
    height: 56px;
    @media (min-width: 600px) {
      height: 64px;
    }
  }
  @media (max-width: 600px) {
    &.bh-mobile--1 {
      height: 28px;
      width: 28px;
    }
    &.bh-mobile-0 {
      height: 32px;
    }
    &.bh-mobile-0-5 {
      height: 36px;
    }
    &.bh-mobile-1 {
      height: 40px;
    }
    &.bh-mobile-2 {
      height: 48px;
    }
    &.bh-mobile-3 {
      height: 56px;
    }
    &.bh-mobile-4 {
      height: 56px;
    }
    &.bh-mobile-5 {
      height: 64px;
    }
  }

  // Shape
  &.shape-default {
    border-radius: 12px;
  }
  &.shape-tiny {
    border-radius: 4px;
  }
  &.shape-large {
    border-radius: 16px;
  }
  &.shape-small {
    border-radius: 8px;
  }
  &.shape-round {
    border-radius: 100px;
  }

  // Size
  &.size--1 {
    font-size: 11px;
  }
  &.size-0 {
    font-size: 12px;
  }
  &.size-0-5 {
    font-size: 13px;
  }
  &.size-1 {
    font-size: 14px;
  }
  &.size-2 {
    font-size: 16px;
  }
  &.size-3 {
    font-size: 18px;
  }
  &.size-4 {
    font-size: 21px;
  }
  @media (max-width: 600px) {
    &.size-mobile--1 {
      font-size: 11px;
    }
    &.size-mobile-0 {
      font-size: 12px;
    }
    &.size-mobile-0-5 {
      font-size: 13px;
    }
    &.size-mobile-1 {
      font-size: 14px;
    }
    &.size-mobile-2 {
      font-size: 16px;
    }
    &.size-mobile-3 {
      font-size: 18px;
    }
    &.size-mobile-4 {
      font-size: 21px;
    }
  }

  // Weight
  &.bw-default {
    font-weight: normal !important;
  }

  &.bw-bold {
    font-weight: 700;
  }

  &.bw-black {
    font-weight: 900;
  }

  // width
  &.w-160 {
    width: 100%;
    max-width: 160px;
  }
  &.w-120 {
    width: 100%;
    max-width: 120px;
  }
  &.w-100 {
    width: 100%;
    max-width: 100px;
  }

  // Colors
  &.bc-default {
    &:before {
      // background-color: white;
      background-color: transparent; // for gallery "learn more buttons"
      border-color: var(--color-grey-30);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-10);
          border-color: var(--color-grey-50);
        }
      }
    }

    .dark &,
    &.dark {
      color: white;
      &:before {
        background-color: var(--color-grey-50);
        opacity: 0;
      }

      &:after {
        border-color: var(--color-grey-50);
        opacity: 0.3;
      }

      @media (min-width: 900px) {
        &:hover {
          &:before {
            opacity: 0.2;
          }
        }
      }
    }

    &.primaried {
      color: var(--color-primary);
      &:before {
        border-color: var(--color-primary);
        opacity: 1;
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-default-opacity {
    &:before {
      background-color: var(--color-grey-80);
      opacity: 0;
    }

    &:after {
      border-color: var(--color-grey-80);
      opacity: 0.25;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.02;
        }
        &:after {
          opacity: 0.5;
        }
      }
    }

    .dark & {
      color: white;
      &:before {
        background-color: white;
        opacity: 0;
      }

      &:after {
        border-color: white;
        opacity: 0.3;
      }

      @media (min-width: 900px) {
        &:hover {
          &:before {
            opacity: 0.1;
          }
          &:after {
            opacity: 0.5;
          }
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple {
    &:before {
      background-color: var(--color-grey-30);
      opacity: 0;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }

    &.constant {
      &:before {
        opacity: 0.5;
      }
    }

    &.disabled {
      color: var(--color-grey-30);
    }

    .dark & {
      &:not(.light) {
        color: var(--color-grey-50);
        &:before {
          background-color: var(--color-grey-50);
          opacity: 0;
        }

        @media (min-width: 900px) {
          &:hover {
            &:before {
              opacity: 0.2;
            }
          }
        }

        &.disabled {
          color: var(--color-grey-70);
        }
      }
    }
  }
  &.bc-simple-white-60 {
    color: rgba(255, 255, 255, 0.6);
    &:before {
      background-color: white;
      opacity: 0;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.2;
        }
      }
    }
  }

  &.bc-simple-opacity {
    &:before {
      background-color: var(--color-grey-80);
      opacity: 0;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.05;
        }
      }
    }

    .dark & {
      &:not(.light) {
        color: white;
        &:before {
          background-color: white;
          opacity: 0;
        }

        @media (min-width: 900px) {
          &:hover {
            &:before {
              opacity: 0.1;
            }
          }
        }
      }
    }
  }

  &.bc-white-bordered {
    color: var(--color-grey-50);
    &:before {
      background-color: white;
    }
    &:after {
      border: 1px solid var(--color-grey-30);
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-grey-60);
        &:after {
          border-color: var(--color-grey-30);
        }
      }
    }
  }

  &.bc-gray-bordered {
    color: var(--color-grey-50);
    &:before {
      opacity: 0;
    }
    &:after {
      border: 1px solid var(--color-grey-30);
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-grey-70);
        &:after {
          border-color: var(--color-grey-50);
        }
      }
    }

    .dark & {
      color: white;
      &:before {
        background-color: var(--color-grey-90);
        opacity: 0.2;
      }
      &:after {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      @media (min-width: 900px) {
        &:hover {
          &:before {
            opacity: 0.3;
          }
        }
      }
    }

    &.disabled {
      color: var(--color-grey-30);
      &:hover {
        &:after {
          border-color: var(--color-grey-30);
        }
      }
    }
  }

  &.bc-green-bordered {
    color: var(--color-green);
    &:before {
      background-color: white;
    }
    &:after {
      border: 1px solid var(--color-grey-30);
    }

    .dark & {
      &:before {
        background-color: black;
      }
      &:after {
        border-color: white;
        opacity: 0.2;
      }
    }

    &.disabled {
      color: var(--color-grey-30);
      &:hover {
        &:after {
          border-color: var(--color-grey-30);
        }
      }
    }
  }

  &.bc-hard-simple,
  &.bc-hard-simple-no-bg {
    &:before {
      background-color: var(--color-grey-30);
      opacity: 0;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }

    &.disabled {
      color: var(--color-grey-30);
    }

    .dark & {
      color: white;
      &:before {
        background-color: var(--color-grey-50);
        opacity: 0;
      }

      @media (min-width: 900px) {
        &:hover {
          &:before {
            opacity: 0.2;
          }
        }
      }

      &.disabled {
        color: var(--color-grey-70);
      }
    }
  }

  &.bc-hard-simple-no-bg {
    &:before {
      display: none;
    }

    @media (min-width: 900px) {
      &.button-has-icon > app-svg:last-child,
      &.button-has-icon > img:last-child {
        transition:
          margin-left 0.2s ease-in-out,
          top 0.2s ease-in-out;
        margin-left: 0;
        top: 0;
        position: relative;
      }

      &:hover {
        &.button-has-icon > app-svg:last-child,
        &.button-has-icon > img:last-child {
          margin-left: 5px;
        }

        &.button-has-icon.bh--3 > app-svg:last-child,
        &.button-has-icon.bh--3 > img:last-child {
          margin-left: 2px;
        }

        &.vertical-icon {
          &.button-has-icon > app-svg:last-child,
          &.button-has-icon > img:last-child {
            top: 2px !important;
          }
        }
      }
    }
  }

  &.bc-primary {
    color: white;
    font-weight: bold;

    &.pulse {
      animation: primary-pulse 2s infinite;
    }

    &:before {
      background-color: var(--color-primary);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-primary-bright);
        }
      }
    }

    &.disabled {
      &:before {
        background-color: var(--color-grey-50);
      }
      .dark & {
        color: var(--color-grey-50);
        &:before {
          background-color: var(--color-grey-70);
        }
      }
    }
  }

  &.bc-warn,
  &.bc-red {
    color: white;
    font-weight: bold;
    &:before {
      background-color: var(--color-warn);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-warn-light);
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-warn {
    color: var(--color-warn);
    &:before {
      background-color: var(--color-warn);
      opacity: 0;
    }

    &.constant {
      &:before {
        opacity: 0.1;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-warn-light);
        &:before {
          background-color: var(--color-warn-light);
          opacity: 0.075;
        }
        &.constant {
          &:before {
            opacity: 0.1;
          }
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-green {
    color: var(--color-green);

    &:before {
      background-color: var(--color-green);
      opacity: 0;
    }

    &.constant {
      &:before {
        background-color: var(--color-green);
        opacity: 0.075;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-green-light);

        &:before {
          opacity: 0.075;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-blue {
    color: var(--color-blue);

    &.constant {
      &:before {
        background-color: var(--color-blue);
        opacity: 0.075;
      }
    }

    @media (min-width: 900px) {
      &:before {
        background-color: var(--color-blue);
        opacity: 0;
      }
      &:hover {
        &:before {
          opacity: 0.075;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-gray {
    color: var(--color-grey-60);

    &:before {
      background-color: var(--color-grey-50);
      opacity: 0;
    }

    &.constant {
      color: var(--color-grey-80);
      &:before {
        opacity: 0.1;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-grey-80);

        &:before {
          opacity: 0.1;
        }
      }

      &.active {
        opacity: 1;
      }
    }

    .dark & {
      color: var(--color-grey-30) !important;

      &:before {
        background-color: white;
        opacity: 0;
      }

      @media (min-width: 900px) {
        &:hover {
          color: white;
          &:before {
            opacity: 0.1;
          }
        }

        &.active {
          opacity: 1;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-gray-70 {
    color: var(--color-grey-70);
    &:before {
      background-color: var(--color-grey-20);
      opacity: 0;
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-grey-70 {
    color: white;
    &:before {
      background-color: var(--color-grey-70);
      opacity: 1;
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-grey-80 {
    color: white;
    &:before {
      background-color: var(--color-grey-80);
      opacity: 1;
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-gray-40 {
    color: var(--color-grey-40);
    &:before {
      background-color: var(--color-grey-20);
      opacity: 0;
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.5;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    .dark & {
      &:before {
        background-color: white;
      }
      @media (min-width: 900px) {
        &:hover {
          color: white;
          &:before {
            opacity: 0.1;
          }
        }
      }
    }
  }
  &.bc-simple-gray-60 {
    color: var(--color-grey-60);
    &:before {
      background-color: var(--color-grey-40);
      opacity: 0;
    }
    &.inner {
      &:before {
        background-color: var(--color-grey-50);
      }
    }
    @media (min-width: 900px) {
      &:hover,
      &.inferred-hover {
        &:before {
          opacity: 0.2;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-simple-gray-50 {
    color: var(--color-grey-50);
    &:before {
      background-color: var(--color-grey-40);
      opacity: 0;
    }
    @media (min-width: 900px) {
      &:hover {
        color: var(--color-grey-80);
        &:before {
          opacity: 0.2;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }

    .dark & {
      &:before {
        background-color: white;
      }
      @media (min-width: 900px) {
        &:hover {
          color: white;
          &:before {
            opacity: 0.1;
          }
        }
      }
    }
  }

  &.bc-simple-primary,
  &.bc-simple-primary-opacity {
    color: var(--color-primary);
    opacity: 1;
    font-weight: bold;

    &:before {
      background-color: var(--color-primary);
      opacity: 0;
    }

    &.constant {
      color: var(--color-primary);
      &:before {
        opacity: 0.1;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-primary);
        &:before {
          opacity: 0.1;
        }
        &.constant {
          &:before {
            opacity: 0.15;
          }
        }
      }
    }

    .dark & {
      &.bc-simple-primary-opacity {
        color: white;
        &:before {
          background-color: white;
          opacity: 0;
        }

        @media (min-width: 900px) {
          &:hover {
            color: white;
            &:before {
              opacity: 0.1;
            }
          }
        }
      }
    }

    &.disabled {
      filter: brightness(1.5);
    }
  }

  &.bc-simple-primary-no-bg {
    color: var(--color-primary);
    font-weight: bold;

    &:before {
      display: none;
    }

    @media (min-width: 900px) {
      &.button-has-icon > app-svg:last-child,
      &.button-has-icon > img:last-child {
        transition: margin-left 0.2s ease-in-out;
        margin-left: 0;
      }
      &:hover {
        &.button-has-icon > app-svg:last-child,
        &.button-has-icon > img:last-child {
          margin-left: 5px;
        }

        &.button-has-icon.bh--3 > app-svg:last-child,
        &.button-has-icon.bh--3 > img:last-child {
          margin-left: 2px;
        }
      }
    }
  }

  &.bc-primary-bordered {
    color: var(--color-primary);
    font-weight: bold;
    &:before {
      background-color: var(--color-primary) !important;
      opacity: 0;
    }

    &:after {
      border-color: var(--color-primary);
      background-color: transparent;
      opacity: 1;
    }
    &.muted {
      &:after {
        border-color: var(--color-grey-30);
      }
    }

    @media (min-width: 900px) {
      &:hover,
      &.constant {
        &:before {
          opacity: 0.1 !important;
        }
        &:after {
          border-color: var(--color-primary);
        }
      }
    }
  }

  &.bc-warn-bordered {
    color: var(--color-warn);
    &:before {
      background-color: var(--color-warn) !important;
      opacity: 0;
    }

    &:after {
      border-color: var(--color-warn);
      background-color: transparent;
    }
    &.muted {
      &:after {
        border-color: var(--color-grey-30);
      }
    }

    @media (min-width: 900px) {
      &:hover,
      &.constant {
        &:before {
          opacity: 0.1 !important;
        }
        &:after {
          border-color: var(--color-warn);
        }
      }
    }
  }

  &.bc-simple-orange {
    color: var(--color-orange);
    opacity: 1;
    font-weight: bold;
    &:before {
      background-color: var(--color-orange);
      opacity: 0;
    }

    &.constant {
      &:before {
        opacity: 0.1;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.1;
        }
        &.constant {
          &:before {
            opacity: 0.15;
          }
        }
      }
    }

    &.disabled {
      filter: brightness(1.5);
    }
  }
  &.bc-simple-purple {
    color: var(--color-purple);
    opacity: 1;
    font-weight: bold;
    &:before {
      background-color: var(--color-purple);
      opacity: 0;
    }

    &.constant {
      &:before {
        opacity: 0.1;
      }
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.1;
        }
        &.constant {
          &:before {
            opacity: 0.15;
          }
        }
      }
    }

    &.disabled {
      filter: brightness(1.5);
    }
  }

  &.bc-simple-yellow {
    color: var(--color-yellow);
    opacity: 1;
    font-weight: bold;
    &:before {
      background-color: var(--color-yellow);
      opacity: 0;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.1;
        }
      }
    }

    &.disabled {
      filter: brightness(1.5);
    }
  }

  &.bc-vote-star {
    color: var(--color-grey-40);
    opacity: 1;
    font-weight: bold;
    &:before {
      background-color: var(--color-grey-40);
      opacity: 0;
    }

    &.active {
      color: var(--color-yellow);
      &:before {
        background-color: var(--color-yellow);
      }
    }

    // @media (min-width: 900px) {
    //   &:hover {
    //     &:before {
    //       opacity: 0.1;
    //     }
    //   }
    // }

    &.disabled {
      filter: brightness(1.5);
    }
  }

  &.bc-edit-gallery {
    color: white;
    &:before {
      border-color: white;
      opacity: 0.4;
    }

    @media (min-width: 900px) {
      &:hover {
        color: var(--color-grey-80);
        &:before {
          background-color: white;
          opacity: 1;
        }
      }
    }
  }

  &.bc-gray,
  &.bc-grey-50 {
    color: white;
    &:before {
      background-color: var(--color-grey-50);
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-40);
        }
      }
    }

    .dark & {
      color: var(--color-grey-50);
      &:before {
        background-color: var(--color-grey-80);
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-grey-60 {
    color: white;
    &:before {
      background-color: var(--color-grey-60);
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-50);
        }
      }
    }
  }

  &.bc-grey-90 {
    color: white;
    &:before {
      background-color: var(--color-grey-90);
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-80);
        }
      }
    }
  }

  // &.bc-black {
  //   color: white;
  //   &:before {
  //     background-color: var(--color-grey-90);
  //   }

  //   @media (min-width: 900px) {
  //     &:hover {
  //       &:before {
  //         filter: brightness(2);
  //       }
  //     }
  //   }

  //   .dark & {
  //     color: var(--color-grey-50);
  //     &:before {
  //       background-color: var(--color-grey-80);
  //     }
  //   }

  //   &.disabled {
  //     opacity: 0.5;
  //   }
  // }

  &.bc-white {
    font-weight: bold;
    &:before {
      background-color: white;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-20);
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-white-green {
    color: var(--color-green);

    &:before {
      background-color: white;
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-20);
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-purple {
    color: white;

    &:before {
      background-color: var(--color-purple);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }

  &.bc-orange {
    color: white;

    &:before {
      background-color: var(--color-orange);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }

  &.bc-green {
    color: white;

    &:before {
      background-color: var(--color-green);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }

  &.bc-blue {
    color: white;

    &:before {
      background-color: var(--color-blue);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }

  &.bc-grey-20 {
    &:before {
      background-color: var(--color-grey-20);
    }

    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-30);
        }
      }
    }
  }

  &.bc-blur {
    color: white;

    &:before {
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(8px);
    }

    &:after {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    @media (min-width: 900px) {
      &:hover:not(.fake-disabled) {
        &:before {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    &.disabled,
    &.fake-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  &.bc-dark-blur {
    color: white;
    &:before {
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
    }

    &.active {
      &:before {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    @media (min-width: 900px) {
      &:hover:not(.fake-disabled) {
        &:before {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    &.disabled,
    &.fake-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }

  &.bc-darker-blur {
    color: rgba(255, 255, 255, 0.4);
    &:before {
      background-color: rgba(31, 35, 46, 0.5);
      backdrop-filter: blur(2px);
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-80);
        }
      }
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.bc-darker-blur-hard {
    color: white;
    &:before {
      background-color: rgba(31, 35, 46, 0.5);
      backdrop-filter: blur(2px);
    }
    @media (min-width: 900px) {
      &:hover {
        &:before {
          background-color: var(--color-grey-80);
        }
      }
    }
  }

  // Has icon
  &.button-has-icon {
    display: flex;
    align-items: center;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    > app-svg,
    > .custom-svg,
    > img {
      width: 32px;
      height: 32px;
      display: block;
      flex: 0 0 auto;

      &:first-child {
        margin-right: 2px;
        &:not([size]) {
          margin-left: -6px;
        }
        &[size='-0-5'] {
          margin-left: -4px;
        }
        &[size='0-5'] {
          margin-left: -4px;
        }
        &[size='0'] {
          margin-left: -4px;
        }
        &[size='-1'] {
          margin-left: -4px;
        }
      }
      &:last-child {
        margin-left: 4px;
        &:not([size]) {
          margin-right: -8px;
        }

        &[size='-0-5'] {
          margin-right: -4px;
        }
        &[size='0-5'] {
          margin-right: -4px;
        }
        &[size='-1'] {
          margin-right: -5px;
          margin-left: 0px;
        }
        &[size='-2'] {
          margin-right: 0;
        }
      }

      &:first-child:last-child {
        margin-left: -6px;
        margin-right: -6px;
      }

      + app-svg,
      + .custom-svg {
        margin-left: 0 !important;
      }
    }

    &.bs-1 {
      > app-svg,
      > .custom-svg {
        &:first-child {
          margin-left: -4px;
        }
        &:last-child {
          &:not([size]) {
            margin-right: -3px;
          }
        }
      }
    }

    &.double-icon {
      app-svg {
        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-left: 0;
        }
      }
    }

    &.its-1 {
      > app-svg {
        &:first-child {
          margin-right: 2px;
        }
        &:last-child {
          margin-left: 2px;
        }
      }
    }
    &.its-0 {
      > app-svg {
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
    &.its-3 {
      > app-svg {
        &:first-child {
          margin-right: 6px;
        }
        &:last-child {
          margin-left: 6px;
        }
      }
    }

    &.vertical-icon {
      flex-direction: column;
      height: 52px;

      app-svg {
        margin: 0 !important;
      }

      &.bh-1 {
        height: 64px;
      }

      &.bh-3 {
        height: 78px;
      }

      @media (max-width: 600px) {
        &.bh-mobile-1 {
          height: 64px;
        }
      }
    }

    &.hide-text-on-mobile {
      @media (max-width: 600px) {
        &.bh-0,
        &.bh-mobile-0 {
          width: 32px;
        }
        &.bh-1,
        &.bh-mobile-1 {
          width: 40px;
        }
        &.bh-2,
        &.bh-mobile-2 {
          width: 48px;
        }
        &.bh-3,
        &.bh-mobile-3 {
          width: 56px;
        }
        app-svg {
          margin: 0;
        }
        span {
          display: none;
        }
      }
    }
  }
  &.full-width {
    width: 100%;
    justify-content: flex-start;
  }
  &.full-width-mobile {
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  &.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.bh--2 {
      width: 20px;
      height: 20px;

      app-svg,
      img {
        width: 16px;
        height: 16px;
      }
    }
    &.bh--1-5 {
      width: 24px;
    }
    &.bh--1 {
      width: 28px;

      app-svg,
      img {
        width: 24px;
        height: 24px;
      }
    }
    &.bh-0 {
      width: 32px;
    }
    &.bh-1 {
      width: 40px;
    }
    &.bh-2 {
      width: 48px;
    }
    &.bh-3 {
      width: 56px;
    }
    &.bh-4 {
      width: 56px;
      @media (min-width: 600px) {
        width: 64px;
      }
    }

    @media (max-width: 600px) {
      &.bh-mobile--2 {
        width: 20px;
        height: 20px;

        app-svg,
        img {
          width: 16px;
          height: 16px;
        }
      }
      &.bh-mobile--1 {
        width: 28px;

        app-svg,
        img {
          width: 24px;
          height: 24px;
        }
      }
      &.bh-mobile-0 {
        width: 32px;
      }
      &.bh-mobile-0-5 {
        width: 36px;
      }
      &.bh-mobile-1 {
        width: 40px;
      }
      &.bh-mobile-2 {
        width: 48px;
      }
      &.bh-mobile-3 {
        width: 56px;
      }
      &.bh-mobile-4 {
        width: 56px;
      }
      &.bh-mobile-5 {
        width: 64px;
      }
    }

    app-svg,
    img {
      width: 32px;
      height: 32px;
      display: block;
    }
  }

  &.center-button {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 900px) {
    &.b-a-d-center {
      margin-left: auto;
      margin-right: auto;
    }
    &.b-a-d-left {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 600px) {
    @media (max-width: 900px) {
      &.b-a-t-center {
        margin-left: auto;
        margin-right: auto;
      }
      &.b-a-t-left {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 600px) {
    &.b-a-m-center {
      margin-left: auto;
      margin-right: auto;
    }
    &.b-a-m-left {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.disabled,
  &.no-interaction,
  &:disabled {
    pointer-events: none;
  }

  &.align-left {
    text-align: left;
    justify-content: left;
  }

  &.ba-left {
    text-align: left;
    justify-content: left;
    padding-left: 10px;

    &.bh-2 {
      padding-left: 15px;
    }

    > app-svg {
      &:last-child {
        margin-left: auto;
        margin-right: 4px;
      }
    }
  }

  &.b-min-w-2 {
    min-width: 140px;
  }

  &.no-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.no-top-radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.no-right-radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Button groups
.button-group {
  display: flex;
  position: relative;
  .custom-button {
    z-index: 1;

    &:hover {
      z-index: 2;
    }

    &:first-child:not(:last-child),
    &.first-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;
    }

    &:last-child:not(:first-child),
    &.last-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:first-child) {
      &:not(:last-child) {
        border-radius: 0;
      }
    }
  }

  &.with-spacing {
    .custom-button {
      margin-left: 2px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.header-buttons {
  display: flex;
  align-items: center;

  .header-button-group {
    display: flex;
    align-items: center;

    @media (min-width: 600px) {
      margin-left: 8px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  > .custom-button {
    margin-left: 6px;

    @media (min-width: 600px) {
      margin-left: 10px;
    }

    &.small-spacing {
      margin-left: 4px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.button-menu {
  padding: 10px;

  > a {
    text-decoration: none;
  }

  &.bottomless {
    padding-bottom: 0;
  }

  .custom-button {
    justify-content: flex-start;
    padding: 0 10px;

    span {
      flex: 1 1 auto;
      text-align: left;
    }
  }

  .divider {
    margin: 10px -10px;
  }
}

app-button-menu-item.featured {
  .custom-button {
    @extend .custom-button, .bc-simple-primary;
  }
}

.button-loading-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.button-hitbox-extender {
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 14px);
  height: calc(100% + 20px);
  z-index: -1;
}

.above-other-hitboxes {
  z-index: 2;
}

@keyframes primary-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(67, 83, 255, 0.8);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(67, 83, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(67, 83, 255, 0);
  }
}

.custom-button b.hidden {
  display: block;
  height: 0;
  opacity: 0;
}

.combined-buttons {
  display: flex;

  .custom-button {
    &.first-combined,
    &.first-combined:before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.last-combined,
    &.last-combined:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .separator {
    position: absolute;
    left: 0;
    top: 16px;
    bottom: 16px;
    background-color: white;
    opacity: 1;
    content: '';
    width: 1px;
    opacity: 0.1;
    transition: opacity 0.2s ease;
  }

  &.small {
    .separator {
      top: 10px;
      bottom: 10px;
    }
  }

  &.tiny {
    .separator {
      top: 4px;
      bottom: 4px;
    }
  }

  &:hover {
    .separator {
      opacity: 0;
    }
  }
}

.inner-button {
  margin-left: 3px;
}

.has-inner-button {
  padding-right: 10px !important;
}

.button-content {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 2px 0;

  &.full-width {
    width: 100%;
  }

  > app-badge,
  > app-upgrade-badge {
    margin-left: 4px;
    margin-right: 2px;
    flex: 0 0 auto;
  }

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sub-cta {
  margin-top: 10px;
}
