a {
  color: currentColor;
}
a.primary-link,
.primary-link,
.primary-link-opacity,
.force-primary-links a {
  cursor: pointer;
  font-weight: 700;
  color: var(--color-primary);
  text-decoration: none;

  &.with-external-link-icon {
    position: relative;
    padding-right: 17px;
    white-space: nowrap;

    &:after {
      position: absolute;
      content: '';
      background-image: url('/assets/icons/special/arrow-external.svg');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.with-arrow {
    position: relative;
    padding-right: 22px;
    white-space: nowrap;

    &.center {
      display: block;
      margin: 0 auto;
      width: fit-content;
    }

    &:after {
      position: absolute;
      content: '';
      background-image: url('/assets/icons/special/chevron-right-primary.svg');
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
      width: 24px;
      height: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.2s ease;

      .dark & {
        background-image: url('/assets/icons/special/chevron-right-white.svg');
      }
    }

    &:hover {
      &:after {
        transform: translateY(-50%) translateX(2px);
      }
    }
  }

  .dark & {
    color: white;
  }

  &:hover {
    opacity: 0.85;
  }
}

a.simple-link,
.simple-link {
  cursor: pointer;
  color: var(--color-grey-90);
  text-decoration: underline;

  .dark & {
    color: white;
  }

  &:hover {
    opacity: 0.85;
  }
}
