.dialog {
  text-align: center;

  h1 {
    font-weight: 900;
  }

  p {
    font-size: 18px;
    color: var(--color-grey-60);
    margin: 10px 0 20px;
    line-height: 1.3;

    b {
      color: var(--color-grey-80);
    }
  }

  .big-icon {
    margin: 0 auto 10px;
    color: var(--color-grey-50);
    display: flex;
    justify-content: center;

    &[size='3'] {
      width: 96px;
      height: 96px;
    }
  }
}
