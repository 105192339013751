.align-left-child-pages {
  .page,
  app-block-wrapper {
    margin: 0;
  }
}

.storyblok-page {
  &.dark {
    .section:not(.white) {
      color: white;
    }
  }
}

app-svg {
  width: 32px;
  height: 32px;
  display: block;
  transform: translateZ(0);
  background-color: currentColor;
  mask-image: var(--mask-image);
  -webkit-mask-image: var(--mask-image);
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  &[size='-2'] {
    width: 12px !important;
    height: 12px !important;
  }
  &[size='-1'] {
    width: 16px !important;
    height: 16px !important;
  }

  &[size='-0-5'] {
    width: 18px !important;
    height: 18px !important;
  }

  &[size='0'] {
    width: 24px !important;
    height: 24px !important;
  }
  &[size='0-5'] {
    width: 28px !important;
    height: 28px !important;
  }

  &[size='1-5'] {
    width: 48px !important;
    height: 48px !important;
  }

  &[size='2'] {
    width: 64px;
    height: 64px;
  }

  &[size='3'] {
    width: 96px;
    height: 96px;
  }

  &[color='gray'],
  &[color='grey-50'] {
    color: var(--color-grey-50);
  }
  &[color='grey-40'] {
    color: var(--color-grey-40);
  }
  &[color='grey-30'] {
    color: var(--color-grey-30);
  }
  &[color='grey-10'] {
    color: var(--color-grey-10);
  }
  &[color='warn'],
  &[color='red'] {
    color: var(--color-warn);
  }
  &[color='green'] {
    color: var(--color-green);
  }
  &[color='yellow'] {
    color: var(--color-yellow);
  }
  &[color='orange'] {
    color: var(--color-orange);
  }
  &[color='purple'] {
    color: var(--color-purple);
  }
  &[color='white'] {
    color: white;
  }
  &[color='primary'] {
    color: var(--color-primary);
  }
  &[color='blue'] {
    color: var(--color-blue);
  }

  &[color='gradient'] {
    color: var(--color-primary);
  }
}

[bg-color='grey-50'] {
  background-color: var(--color-grey-50);
}
[bg-color='grey-10'] {
  background-color: var(--color-grey-10);
  color: var(--color-grey-50);
}
[bg-color='warn'],
[bg-color='red'] {
  background-color: var(--color-warn);
}
[bg-color='green'] {
  background-color: var(--color-green);
}
[bg-color='yellow'] {
  background-color: var(--color-yellow);
}
[bg-color='orange'] {
  background-color: var(--color-orange);
}
[bg-color='purple'] {
  background-color: var(--color-purple);
}
[bg-color='primary'] {
  background-color: var(--color-primary);
}
[bg-color='blue'] {
  background-color: var(--color-blue);
}
[bg-color='gradient'] {
  background-color: var(--color-primary);
  background:
    conic-gradient(
      from 90deg at 50% 50%,
      #6dd400 -28.9deg,
      #3eacff 37.27deg,
      #8c6cff 96.26deg,
      #b620e0 133.29deg,
      #e02020 175.18deg,
      #fa6400 228.63deg,
      #f7b500 272.06deg,
      #6dd400 331.1deg,
      #3eacff 397.27deg
    ),
    #000000;
}

.text-color-gray {
  color: var(--color-grey-60);
}
.text-color-warn,
.text-color-red {
  color: var(--color-warn);
}
.text-color-green {
  color: var(--color-green);
}
.text-color-yellow {
  color: var(--color-yellow);
}
.text-color-orange {
  color: var(--color-orange);
}
.text-color-purple {
  color: var(--color-purple);
}
.text-color-primary,
.text-color-blue {
  color: var(--color-primary);
}

.center {
  text-align: center;
}

.settings-box-group {
  margin-bottom: 60px;
  position: relative;
}

.list {
  .list-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding: 1px 0;

    @media (min-width: 600px) {
      font-size: 18px;
      padding: 4px 0;
    }

    .list-item-icon {
      display: block;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      color: var(--color-primary);
      margin-right: 2px;

      @media (min-width: 600px) {
        width: 32px;
        height: 32px;
      }
    }

    &.faded {
      // color: var(--color-grey-50);
      opacity: 0.5;
      font-weight: normal;

      .list-item-icon {
        color: currentColor;
      }
    }

    .primary & {
      .list-item-icon {
        color: white;
        // border: 2px solid white;
        // border-radius: 50%;
      }
    }

    .dark & {
      .list-item-icon {
        color: white;
        // color: var(--color-grey-80);
        // background-color: white;
        // border: 2px solid white;
        // border-radius: 50%;
      }
    }
  }
}

.divider {
  border-bottom: 1px solid var(--color-grey-30);
}

app-section-container {
  display: block;
  position: relative;
}

.section {
  display: block;
  &.dark {
    &:not(.dark-no-bg) {
      background-color: var(--color-grey-90);
    }
    color: white;
    &.black {
      background-color: black;
    }
    &.transparent {
      background-color: transparent;
    }
  }
  &.white:not(.white-no-bg) {
    background-color: white;
  }
  &.grey-10 {
    background-color: var(--color-grey-10);
  }
  &.grey-10-fade {
    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      height: 360px;
      background: linear-gradient(0deg, var(--color-grey-10) 0%, #ffffff 100%);
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

.sticky-container {
  position: relative;
}

.spaced-button-group {
  display: flex;
  transition: opacity 0.2s ease;
  --button-group-br: 12px;
  border-radius: var(--button-group-br);

  &.spaced-button-group-round {
    --button-group-br: 100px;
  }

  &.with-shadow {
    filter: drop-shadow(0px 8px 22px #2a2c3026);
  }

  &.with-border {
    border: 1px solid var(--color-grey-10);
  }

  &.vertical {
    flex-direction: column;

    .spaced-button-group-item {
      flex-direction: column;

      &:first-child:not(:last-child) {
        border-radius: var(--button-group-br) var(--button-group-br) 0 0;
      }

      &:last-child:not(:first-child) {
        border-radius: 0 0 var(--button-group-br) var(--button-group-br);
      }

      &:not(:first-child) {
        padding-left: 8px;
        &:after {
          top: 4px;
          height: 1px;
          width: auto;
          left: 12px;
          right: 12px;
          bottom: auto;
        }
      }
    }
  }

  &.small {
    .spaced-button-group-item {
      padding: 2px;
    }

    &:not(.vertical) {
      .spaced-button-group-item {
        &:not(:first-child) {
          padding-left: 8px;
        }
      }
    }

    &.vertical {
      .spaced-button-group-item {
        &:not(:first-child) {
          padding-top: 8px;
          padding-left: 2px;
        }
      }
    }
  }

  .spaced-button-group-item {
    padding: 8px;
    background-color: white;
    display: flex;
    position: relative;

    &:first-child:not(:last-child) {
      border-radius: var(--button-group-br) 0 0 var(--button-group-br);
    }

    &:last-child:not(:first-child) {
      border-radius: 0 var(--button-group-br) var(--button-group-br) 0;
    }

    &:first-child:last-child {
      border-radius: var(--button-group-br);
    }

    &:not(:first-child) {
      padding-left: 17px;
      &:after {
        position: absolute;
        content: '';
        left: 4px;
        width: 1px;
        top: 12px;
        bottom: 12px;
        background-color: var(--color-grey-30);
      }
    }

    &.item-under {
      &:before {
        position: absolute;
        content: '';
        inset: 0;
        background: linear-gradient(270deg, #f1f5f8 0%, rgba(241, 245, 248, 0) 14.17%);
      }

      + .spaced-button-group-item {
        padding-left: 5px;
        &:after {
          display: none;
        }
      }
    }
  }
}

.legal {
  font-size: 14px;

  &.small {
    font-size: 12px;
    color: var(--color-grey-60);

    a {
      color: var(--color-grey-70);
    }
  }
}

@media (max-width: 600px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .hide-on-tablet {
    display: none !important;
  }
}
@media (min-width: 900px) {
  .hide-on-desktop {
    display: none !important;
  }
}

.info-message {
  display: flex;
  color: var(--color-grey-50);
  app-svg {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    flex: 0 0 auto;
  }
  span {
    font-size: 14px;
    line-height: 1.2;
  }
}

.dragging {
  cursor: grabbing;
  > * {
    cursor: grabbing !important;
  }

  app-gallery-item {
    cursor: grabbing !important;
  }
}

.split-page {
  display: flex;
  > div {
    width: 50%;
    flex: 1 1 auto;
  }
}

.crossed-out {
  position: relative;
  margin-right: 10px;
  line-height: 1.4;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: -4px;
    width: calc(100% + 8px);
    // border-bottom: 2px solid var(--color-warn);
    height: 2px;
    background-color: var(--color-warn);
    border-radius: 100px;
    transform: rotate(-3.5deg);
    opacity: 0.8;
  }

  &.short {
    &:after {
      left: -1px;
      width: calc(100% + 2px);
    }
  }
}

.hidden-text {
  opacity: 0;
}

.standard-text {
  color: var(--color-grey-60);

  &.grey-70 {
    color: var(--color-grey-70);
  }

  b,
  strong {
    color: var(--color-grey-80);
  }

  .dark & {
    color: var(--color-grey-50);

    b,
    strong {
      color: white;
    }
  }

  &.size-2-5 {
    font-size: 16px;
    @media (min-width: 600px) {
      font-size: 18px;
    }
  }

  &.size-3 {
    font-size: 18px;
    @media (min-width: 600px) {
      font-size: 21px;
    }
  }

  &.small {
    font-size: 14px;
    line-height: 1.2;
  }

  &.standard-spacing {
    line-height: 1.2;
  }
}

.standard-dark-text {
  color: var(--color-grey-70);

  b,
  strong {
    color: var(--color-grey-80);
  }

  .dark & {
    color: white;

    b,
    strong {
      color: white;
    }
  }
}

.badges {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  .badge {
    display: flex;
    align-items: center;
    padding: 4px;
    width: 115px;
    background-color: white;
    border: 1px solid var(--color-grey-20);
    border-radius: 12px;
    text-align: left;
    font-weight: bold;
    font-size: 11px;
    line-height: 1.2;
    transition:
      border-color 0.2s ease,
      background-color 0.2s ease;

    &:hover {
      border-color: var(--color-grey-30);
      background-color: var(--color-grey-10);
    }

    .dark & {
      border: 1px solid var(--color-grey-80);
      background-color: var(--color-grey-90);

      &:hover {
        border-color: var(--color-grey-70);
        background-color: var(--color-grey-90);
      }
    }

    img {
      flex: 0 0 auto;
      margin-right: 2px;
      width: 32px;
      height: 32px;
    }
  }
}

.wysiwyg-content {
  mention {
    display: inline-block;
    // color: var(--color-grey-70);
    position: relative;
    z-index: 1;
    padding: 2px 6px 2px 24px;
    white-space: normal;
    line-height: 1.2;
    height: 20px;
    cursor: pointer;
    font-size: 14px;

    .avatar {
      width: 18px;
      height: 18px;
      position: absolute;
      left: 2px;
      top: 1px;
      border-radius: 50%;
    }

    &:before {
      inset: -1px 0;
      content: '';
      position: absolute;
      background-color: var(--color-grey-50a20);
      z-index: -1;
      border-radius: 100px;
    }

    // .dark & {
    //   color: white;
    // }

    // .create-comment-overlay & {
    //   color: var(--color-grey-70);
    // }
  }
}

.wysiwyg-content {
  a {
    cursor: pointer;
  }

  b {
    font-weight: 900;
  }

  &.no-style {
    * {
      font-weight: inherit !important;
      font-style: inherit !important;
    }
  }
}

.grey-50 {
  color: var(--color-grey-50);
}

qrcode canvas {
  width: 100% !important;
  height: 100% !important;
}

.rounded-banner {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 14px;
  border-radius: 12px;
  font-weight: bold;
  color: var(--color-primary);
  background-color: rgba(67, 83, 255, 0.1);

  &.warn {
    color: var(--color-warn);
    background-color: rgba(251, 79, 79, 0.1);
  }

  app-svg {
    margin-right: 6px;
  }

  span {
    flex: 1 1 auto;
  }
}

app-block-wrapper {
  display: block;
}

.circle-icon {
  width: 36px;
  height: 36px;
  padding: 2px;
  position: relative;
  border-radius: 50%;

  &.orange {
    color: var(--color-orange);
    background-color: var(--color-orangea10);
  }
}

.animated-circle-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: white;
  display: grid;
  place-items: center;
  position: relative;

  &:before,
  &:after {
    background-color: var(--color-grey-50);
    position: absolute;
    content: '';
    border-radius: 50%;
    background-color: inherit;
  }

  &:before {
    opacity: 0.25;
    inset: 0;
    z-index: 1;
    animation: circleBefore 0.2s ease-out;
  }

  &:after {
    opacity: 1;
    inset: 6px;
    z-index: 2;
    animation: circleAfter 0.2s ease-out;
  }

  app-svg {
    z-index: 3;
    position: relative;
  }

  &.huge {
    width: 96px;
    height: 96px;
    app-svg {
      width: 48px;
      height: 48px;
    }

    &:before {
      animation: circleBeforeHuge 0.2s ease-out;
    }

    &:after {
      inset: 12px;
      animation: circleAfterHuge 0.2s ease-out;
    }
  }

  &.ginormous {
    width: 128px;
    height: 128px;
    app-svg {
      width: 64px;
      height: 64px;
    }

    &:before {
      animation: circleBeforeGinormous 0.2s ease-out;
    }

    &:after {
      inset: 16px;
      animation: circleAfterGinormous 0.2s ease-out;
    }
  }

  &.green {
    &:before,
    &:after {
      background-color: var(--color-green);
    }
  }

  &.orange {
    &:before,
    &:after {
      background-color: var(--color-orange);
    }
  }
  &.primary {
    &:before,
    &:after {
      background-color: var(--color-primary);
    }
  }
}

@keyframes circleBefore {
  from {
    inset: 3px;
  }
  to {
    inset: 0;
  }
}

@keyframes circleBeforeHuge {
  from {
    inset: 6px;
  }
  to {
    inset: 0;
  }
}

@keyframes circleBeforeGinormous {
  from {
    inset: 12px;
  }
  to {
    inset: 0;
  }
}

@keyframes circleAfter {
  from {
    inset: 3px;
  }
  to {
    inset: 6px;
  }
}
@keyframes circleAfterHuge {
  from {
    inset: 6px;
  }
  to {
    inset: 12px;
  }
}
@keyframes circleAfterGinormous {
  from {
    inset: 12px;
  }
  to {
    inset: 16px;
  }
}

mark {
  // used in storyblok html
  background-color: rgba(255, 212, 72, 0.2);
  border-radius: 4px;
  padding: 0 2px;
  color: inherit;
}
