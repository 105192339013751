/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

// set line height of headings
h1,
h2,
h3,
h4 {
  line-height: 1.2;
}

/* Set core body defaults */
body {
  // min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  user-drag: none;
  -webkit-user-drag: none;
}

/* Make images easier to work with */
// approot for html2canvas
img,
svg {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
