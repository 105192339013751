.page {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
  --page-padding: 15px;
  max-width: calc(1180px + var(--page-padding) * 2);

  &.no-horizontal-padding {
    --page-padding: 0px;
  }

  @media (min-width: 600px) {
    --page-padding: 20px;
    padding: 20px var(--page-padding);

    &.no-horizontal-padding {
      --page-padding: 0px;
      padding: 15px var(--page-padding);
    }
  }
  @media (min-width: 900px) {
    --page-padding: 40px;
  }

  &.tiny {
    max-width: calc(380px + var(--page-padding) * 2);
  }

  &.page--1 {
    max-width: calc(480px + var(--page-padding) * 2);
  }

  &.page-0-5 {
    max-width: calc(560px + var(--page-padding) * 2);
  }

  &.small,
  &.page-0 {
    max-width: calc(780px + var(--page-padding) * 2);
  }

  &.smallish {
    max-width: calc(900px + var(--page-padding) * 2);
  }

  &.medium {
    max-width: calc(1000px + var(--page-padding) * 2);
  }

  &.medium-2 {
    max-width: calc(1140px + var(--page-padding) * 2);
  }

  &.extra-large,
  &.page-2 {
    max-width: calc(1400px + var(--page-padding) * 2);
  }

  &.full {
    max-width: 100%;
  }

  &.headless {
    padding-top: 0;
  }
  &.bottomless {
    padding-bottom: 0;
  }
}
