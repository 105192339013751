.logo-el {
  display: flex;
  align-items: center;
  color: var(--color-grey-80);
  text-decoration: none;
  position: relative;
  padding-left: 4px;

  @media (max-width: 600px) {
    padding-left: 4px;
  }

  .section.dark & {
    color: white;
  }

  img {
    display: block;
    flex: 0 0 auto;
    width: 30px;
    height: 30px;

    @media (min-width: 600px) {
      width: 32px;
      height: 32px;
    }
  }

  svg {
    display: block;
    width: 100%;
  }

  span {
    font-size: 20px;
    font-weight: 900;
    line-height: 1;
    display: block;
    margin-top: 1px;
    margin-left: 4px;

    @media (min-width: 600px) {
      margin-top: 2px;
      font-size: 22px;
    }
  }
}
