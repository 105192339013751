@use './styles/reset';
@use './styles/variables';
@use './styles/fonts';
@use './styles/logo';
@use './styles/buttons';
@use './styles/forms';
@use './styles/page';
@use './styles/layout';
@use './styles/links';
@use './styles/settings';
@use './styles/dialogs';
@use './styles/landing-pages';
@use './styles/clerk-reset';

body {
  font-family:
    AkkuratLL,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  color: var(--color-grey-80);
  width: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  --scrollbar-width: 0px;
  padding-right: var(--scrollbar-width);
  touch-action: pan-y;
  font-size: 16px;
}

.instagram-media,
.twitter-tweet {
  margin: 0 auto !important;
}

.intercom-lightweight-app {
  z-index: 2000 !important;
}

canvas#confetti {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#credential_picker_iframe {
  visibility: hidden;
  .show-mobile-google-login & {
    visibility: unset;
  }
}
