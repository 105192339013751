@font-face {
  font-family: AkkuratLL;
  src:
    url('/assets/fonts/subset-e0b619674400d7387d042996f58c9e54.woff2') format('woff2'),
    url('/assets/fonts/subset-e0b619674400d7387d042996f58c9e54.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: AkkuratLL;
  src:
    url('/assets/fonts/subset-2d9d98a25b1128f89314b16b8937e605.woff2') format('woff2'),
    url('/assets/fonts/subset-2d9d98a25b1128f89314b16b8937e605.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AkkuratLL;
  src:
    url('/assets/fonts/subset-b19712e21f8854ab9e035d1c465914bd.woff2') format('woff2'),
    url('/assets/fonts/subset-b19712e21f8854ab9e035d1c465914bd.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
