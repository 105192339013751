.cl-cardBox {
  box-shadow: none;
  padding: 0;
  width: 100%;
  max-width: unset;
  border-radius: 0;
  overflow: visible !important;
}

.cl-card {
  border: none;
  box-shadow: none;
  padding: 0;
}

.cl-header {
  display: none;
}

.cl-socialButtons {
  @media (max-width: 30em) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cl-socialButtonsProviderIcon,
.cl-socialButtonsProviderIcon img {
  width: 21px !important;
  height: 21px !important;
  max-width: unset !important;
  display: block !important;
}

.cl-button {
  font-size: 16px;
  height: 64px;
  text-transform: none;
  border-radius: 100px;
  font-weight: bold;
  font-size: 18px;
  box-shadow: none !important;
  border: 1px solid var(--color-grey-30) !important;
  color: var(--color-grey-80);

  &.cl-formButtonPrimary {
    border: none !important;
  }

  &:hover {
    border-color: var(--color-grey-50) !important;
    background-color: var(--color-grey-10);
  }

  &:active {
    border-color: var(--color-grey-50) !important;
    background-color: var(--color-grey-10);
    transform: scale(0.98);
  }

  span {
    font-size: 18px;
    font-weight: bold;
  }

  &:focus {
    box-shadow: none;
  }

  &[data-color='primary'][data-variant='solid'] {
    background-color: var(--color-primary);
    box-shadow: none !important;
    color: white;

    &:after {
      display: none;
    }

    &:hover {
      background-color: var(--color-primary-bright);
    }

    svg {
      display: none;
    }
  }

  &[data-variant='link'] {
    font-size: 14px;
    border: none !important;
    height: auto;
  }
}

.cl-formFieldLabelRow {
  display: none;
}

.cl-formFieldLabelRow:has(a) {
  display: flex;
  label {
    display: none;
  }
  a {
    margin-left: auto;
  }
}

.cl-formFieldLabel {
  font-size: 14px;
  color: var(--color-grey-70);
}

.cl-footerActionLink {
  font-size: 14px;
  color: var(--color-grey-70);
}

/* Input spacing */
/* Note: Using internal classes as Clerk doesn't provide stable classes for these wrappers */
.cl-form,
.cl-form > div {
  gap: 1rem !important;
}

.cl-form {
  .cl-formFieldInput {
    &:not([type='checkbox']) {
      border-radius: 12px;
      height: 64px;
      max-height: none;
      font-size: 16px;
      box-shadow: none !important;
      border: 1px solid var(--color-grey-30);

      &::placeholder {
        color: var(--color-grey-50);
      }

      &:hover {
        border-color: var(--color-grey-50);
      }

      &:focus {
        border-color: var(--color-primary) !important;
        // outline-offset: 0;
        box-shadow: 0 0 0 4px rgba(67, 83, 255, 0.25) !important;
      }
    }
  }
}

.cl-rootBox {
  width: 100%;
}

.cl-footer {
  display: none;
}

.cl-identityPreview {
  margin: 0 auto;
  background-color: transparent;
  border: none;
}

.cl-logoBox {
  display: none;
}

.cl-formFieldRow__name {
  display: none;
}

.cl-formFieldInputShowPasswordButton {
  visibility: hidden;
  // height: 40px !important;
  // width: 40px;
  // margin-right: 0px;
  // border: none !important;
}

.cl-formFieldInput__password {
  padding: 0.375rem 0.75rem !important;
}

.cl-dividerRow {
  width: calc(100% - 100px);
  margin: 0 auto;
  text-transform: uppercase;
  p {
    color: var(--color-grey-50);
  }
}

.clerk-component-container {
  margin-top: 20px;
  width: 100%;
}

.cl-otpCodeFieldInputs {
  input {
    height: 72px;
    max-height: 72px;
    width: 52px;
    font-size: 32px;
    border-radius: 12px;
    max-height: none;
    box-shadow: none !important;
    border: 1px solid var(--color-grey-30) !important;
    font-weight: normal;

    &:focus {
      border-color: var(--color-primary) !important;
      // outline-offset: 0;
      box-shadow: 0 0 0 3px rgba(67, 83, 255, 0.25) !important;
    }
  }
}

.cl-dividerLine {
  &:first-child {
    background: linear-gradient(270deg, var(--color-grey-30) 0%, rgba(222, 232, 241, 0) 100%);
  }
  &:last-child {
    background: linear-gradient(270deg, rgba(222, 232, 241, 0) 0%, var(--color-grey-30) 100%);
  }
}
